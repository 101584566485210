import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import SelectClient from "../../lottery/SelectClient";

class SidenavContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        selectedClientCode: window.localStorage.getItem('ClientId')
    };
}

  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
 
       
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  };



  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          
        <li id='select-client-main'>
            <SelectClient selectedClient={this.props.selectedClient} clients={this.props.clients} status={this.props.status} getClientCode={this.props.getClientCode} />
        </li>
        <li className="nav-header">
             <IntlMessages id="sidebar.catalogs"/>
        </li>          
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/lotteries/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-camera-alt zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.lotteries"/>
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/prizes/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-card-giftcard zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.prizes"/>
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/tickets/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-view-web zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.tickets"/>
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/winning/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-cake zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.winning"/>
              </span>
            </NavLink>
          </li>
          <li className="nav-header">
             <IntlMessages id="sidebar.reports"/>
          </li> 
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/lotteryreport/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-camera-alt zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.lotteries"/>
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/conversions/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-swap-alt zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.conversions"/>
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/dynamics/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-trending-up zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.dynamics"/>
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to={"/app/files/" + this.props.selectedClient.clientId}>
              <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
              <span className="nav-text">
                  <IntlMessages id="sidebar.files"/>
              </span>
            </NavLink>
          </li>
        
          
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
