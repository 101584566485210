import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import InfoView from 'components/InfoView';
import {userSignIn} from 'actions/Auth';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    }
  }

  emailChangeHandler=(e)=>{
 
    this.setState({email: e.target.value})

  }

  passwordChangeHandler=(e)=>{

    this.setState({password: e.target.value})

  }

  signInHandler=()=>{
     
      this.props.userSignIn(this.state.email, this.state.password);
 
  }

  // componentDidUpdate() {
  //   if (this.props.authUser !== null) {
  //     this.props.history.push('/');
  //   }
  // }

  render() {
    // const {
    //   email,
    //   password
    // } = this.state;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={require("assets/images/logo.png")} alt="BMS Cloud" title="BMS Cloud"/>
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.loginFormTitle"/></h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email"/>}
                    fullWidth
                    onChange={this.emailChangeHandler}
                    defaultValue={this.state.email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.props.userSignIn(this.state.email, this.state.password);
                      }
                    }}
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password"/>}
                    fullWidth
                    onChange={this.passwordChangeHandler}
                    defaultValue={this.state.password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.props.userSignIn(this.state.email, this.state.password);
                      }
                    }}
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button 
                     style={{
                      borderRadius: 10,
                      backgroundColor: "#45388E",
                      padding: "10px 36px",
                      fontSize: "18px"
                    }}
                    onClick={this.signInHandler} variant="contained" color="primary" type="button">
                      <IntlMessages id="appModule.signin"/>
                    </Button>
                    
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        <InfoView/>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignIn})(SignIn);
