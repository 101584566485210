import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Dropmenu from '../../components/Dropdown/Dropdown'

export default class DropdownComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            selectedClient: '',
            status: ''
        };
    }

    
    render() {
         if (this.props.status === 'error') {
             return ( <Redirect to={'/signin'}/> );
         }

        if (this.props.status === 'ok' && this.props.selectedClient) {
            return (

                <Dropmenu changeClientGlobal={this.props.getClientCode} clients={this.props.clients} selectedClient={this.props.selectedClient}/>

            )
        } else {
            return (
                <p>Loading...</p>
                
            )
        }

    }

}