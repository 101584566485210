import React from 'react';
import IntlMessages from 'util/IntlMessages';
import './logoutButton.css';

const LogoutButton = () => {
  return (
    <span className="jr-link dropdown-item text-muted" onClick={() => {
        localStorage.removeItem("token");
        localStorage.removeItem("ClientName");
        localStorage.removeItem("ClientId");
        window.location.replace("/");
      }}>
        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
        <IntlMessages id="popup.logout"/>
      </span>
  )
};

export default LogoutButton;