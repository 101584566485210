import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};


export const userSignIn = (email, password) => {

  return (dispatch) => {
    
    let authData = new FormData();
    authData.set('login',email);
    authData.set('password',password);

    fetch(`//luckydraw.ae/api/tvx/?func=auth&login=${email}&password=${password}`)
    .then(result => (result.json()))
    .then(data => {
      
      if (data.status === 'ok') {
        localStorage.setItem("token",data.sid);
        localStorage.setItem("user",data.name);

        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SIGNIN_USER_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.sid});
        window.location.replace('/');
      
       
      } else {
        if (data.message) {
          dispatch({type: FETCH_ERROR, payload: data.error});
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        } else {
          dispatch({type: FETCH_ERROR, payload: 'Login error'});
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
      }
    }).catch(function (error) {
      if (error.message) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        localStorage.removeItem("token");
        console.log("Error****:", error.message);
        window.location.replace('/signin');
      }
        dispatch({type: FETCH_ERROR, payload: 'Login error'});
        localStorage.removeItem("token");
        window.location.replace('/signin');
      
    });
  }
};

export const getUser = () => {
   return (dispatch) => {
  //   dispatch({type: FETCH_START});
  //   axios.post('auth/me',
  //   ).then(({data}) => {
  //     console.log("userSignIn: ", data);
  //     if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: 'user'});
  //     } else {
  //       dispatch({type: FETCH_ERROR, payload: data.error});
  //     }
  //   }).catch(function (error) {
  //     dispatch({type: FETCH_ERROR, payload: error.message});
  //     console.log("Error****:", error.message);
  //   });
   }
};


export const userSignOut = () => {
  return () => {
    
    // dispatch({type: FETCH_START});
    // axios.post('auth/logout',
    // ).then(({data}) => {
    //   if (data) {
    //     dispatch({type: FETCH_SUCCESS});
         localStorage.removeItem("token");
         localStorage.removeItem("user");
         window.location.replace("/signin");
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: SIGNOUT_USER_SUCCESS});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  }
};
