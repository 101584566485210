const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb'
  },
  {
    languageId: 'russian', // Unique ID for language
    locale : 'ru',         // locale name to link with the previously named locale
    name : 'Русский',      // Display name of the language
    icon : 'ru'            // To choose the flag of the country
  },
  {
    languageId: 'serbian', // Unique ID for language
    locale : 'sr',         // locale name to link with the previously named locale
    name : 'Srpska',      // Display name of the language
    icon : 'rs'            // To choose the flag of the country
  }
  
  //,
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  //   icon: 'cn'
  // },
  // {
  //   languageId: 'spanish',
  //   locale: 'es',
  //   name: 'Spanish',
  //   icon: 'es'
  // },
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  //   icon: 'fr'
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  //   icon: 'it'
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  //   icon: 'sa'
  // }

];
export default languageData;
