import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {Redirect} from 'react-router-dom';
import configureStore, { history } from "./store";
import App from "./containers/App";

let sid = '';
//Если sid передан в get параметре, значит пришли с главной страницы
const query = new URLSearchParams(window.location.search);
const sidFromPath = query.get('sid');
if (sidFromPath && sidFromPath.length === 32) {
  sid = sidFromPath;
  localStorage.setItem("token", sidFromPath);
  window.location.replace('/');
}

export const store = configureStore();

const MainApp = () =>

  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default MainApp;