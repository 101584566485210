import appLocaleData from 'react-intl/locale-data/sr';
import srMessages from '../locales/sr_RS.json';

const SrLang = {
  messages: {
    ...srMessages
  },
  locale: 'sr-RS',
  data: appLocaleData
};
export default SrLang;