import React from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { NavLink } from 'react-router-dom';
import './Dropdown.css'
import { createBrowserHistory } from "history";
import IntlMessages from "util/IntlMessages";

let selectedClient = (window.localStorage.getItem('ClientName') ? window.localStorage.getItem('ClientName') : <IntlMessages id="reports.chooseClient"/>);
if (selectedClient === 'undefined') {selectedClient = <IntlMessages id="reports.chooseClient"/>};

export default class Dropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clients: this.props.clients
    };
    //selectedClient = this.props.selectedClient.clientName;
    
  }

  selectClient=(e)=> {

    selectedClient = e.target.text;
    const clientId = e.currentTarget.getAttribute('id');
    this.props.changeClientGlobal(clientId);
    window.localStorage.setItem('ClientId', clientId);
    window.localStorage.setItem('ClientName', selectedClient);
  }

  render() {
    selectedClient = window.localStorage.getItem('ClientName');
    const history = createBrowserHistory();
    const pathname = `${history.location.pathname}`;// get current path
    let pathLocation = [];
    const arrPath = pathname.split('/');
    pathLocation = arrPath[2] + '/';

    if (this.props.clients.length > 0) {

      return (

        <MDBDropdown size="sm">
          <MDBDropdownToggle caret color="dark">
            {selectedClient}
          </MDBDropdownToggle>
          <MDBDropdownMenu className='dropBody' basic>

            {this.props.clients.map((client) => {

              return (

                <MDBDropdownItem onClick={this.selectClient} id={client.code} clientname={client.name} key={"dropClient" + client.code}>

                  <NavLink to={"/app/" + pathLocation + client.code} >
                    {client.name.length > 24 ? client.name.substring(0, 24) + "..." : client.name}
                  </NavLink>

                </MDBDropdownItem>

              )

            })}

          </MDBDropdownMenu>
        </MDBDropdown>

      )

    }

  }

}

