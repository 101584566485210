import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import "assets/vendors/style";
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import languageData from '../components/LanguageSwitcher/data';
import {getUser, setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';

let sid = '';

const RestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;
  
class App extends Component {

  UNSAFE_componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
      
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      // axios.defaults.headers.common['Authorization'] = "Bearer " + nextProps.token;
    }
    if (nextProps.token && !nextProps.authUser) {
      this.props.getUser()

    }
  }
  render() {

    const myLocale = localStorage.getItem('locale');
    const loc = languageData[0];
    if (myLocale == '' || myLocale === undefined || myLocale === null) {
      localStorage.setItem('locale', JSON.stringify(loc) );
    } else {
      let GoodLocaleInLocalStorage = 0;
       
    languageData.some(item => 
      GoodLocaleInLocalStorage =
      item.languageId === JSON.parse(myLocale).languageId &&
      item.locale === JSON.parse(myLocale).locale &&
      item.name === JSON.parse(myLocale).name &&
      item.icon === JSON.parse(myLocale).icon
      )
        
     if (!GoodLocaleInLocalStorage) localStorage.setItem('locale', JSON.stringify(loc) );

    }
         
    const {match, location, locale, token, initURL, isDirectionRTL} = this.props;
    
    if (location.pathname === '/') {

      if (token === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/app/lotteries'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} token={token}
                                   component={MainApp}/>
                  <Route path='/signin' component={SignIn}/>
                  
                  <Route
                    component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {authUser, token, initURL} = auth;
  return {sideNavColor, token, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl, getUser})(App);

