import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import LogoutButton from 'lottery/LogoutButton';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth, switchLanguage} from 'actions/Setting';

let lang = 'en';
if (window.localStorage.getItem('locale')) {
  lang = JSON.parse(window.localStorage.getItem('locale')).locale;
}

class SideNav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        //selectedClientCode: window.localStorage.getItem('ClientId'),
        selectedClient: {
          clientId: window.localStorage.getItem('ClientId'),
          clientName: window.localStorage.getItem('ClientName')
        },
        locale: lang
    };
        
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  selectClient = (codeClient) => {
    this.setState({
      //selectedClientCode: codeClient
      selectedClient: {
        clientId: codeClient
      }
            
    });
  };

  getClientFromServer = (locale) => {
    const sid = (window.localStorage.getItem('token') ? window.localStorage.getItem('token').replace(/[""]/g, '') : '');
          
    if (locale) {
      lang = locale;
    } 
    fetch('//luckydraw.ae/api/tvx/?func=getclients&sid=' + sid + '&lang=' + lang)
      .then(res => res.json())
      .then(
          result => {
              if (result.status === 'ok') {
              this.setState({
                  clients: result.clients,
                  status: result.status
              })
              if (result.clients.length === 2) {
                  window.localStorage.setItem('ClientName', result.clients[1].name);
                  window.localStorage.setItem('ClientId', result.clients[1].code);
                  this.setState({
                      selectedClient: {
                          clientName: result.clients[1].name,
                          clientId: result.clients[1].code
                      },
                      status: result.status
                  })
                  
              } else if (result.clients.length > 0) {
                   window.localStorage.setItem('ClientName', result.clients[0].name);
                  window.localStorage.setItem('ClientId', result.clients[0].code);
                  this.setState({
                      selectedClient: {
                          clientName: result.clients[0].name,
                          clientId: result.clients[0].code
                      },
                      status: result.status
                  })
              }
              } else {
                  localStorage.removeItem("token");
                  this.setState({
                      status: result.status
                  })
              }
          
          })
  }

  componentDidMount() {

    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });

    this.getClientFromServer(null);
      
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.locale !== this.props.locale) {
      this.getClientFromServer(nextProps.locale.locale);
    }
      
  }
  

  

  render() {
    
    const {navCollapsed, drawerType, width, navigationStyle} = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={this.onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
        >
          {/* <UserInfo/> */}
          <Link className="app-logo mr-2 d-none d-sm-block center" to="/">
            <img src={require("assets/images/logo_2.png")}  height="32" alt="BMS Cloud" title="BMS Cloud"/>
          </Link>
          <SidenavContent selectedClient={this.state.selectedClient} clients={this.state.clients} status={this.state.status} getClientCode={this.selectClient} />
          <LogoutButton/>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed, locale, drawerType, width, navigationStyle} = settings;
  return {navCollapsed, locale, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth, switchLanguage})(SideNav));

